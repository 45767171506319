<template>
  <div id="discover_module">
    <div class="px-4 py-2 d-flex align-center">
      <div class="font-weight-bold primaryColor" id="discover_module_label">Discover</div>
    </div>
    <v-row class="ma-0 px-4">
      <v-slide-group v-model="infoType" class="pa-0" mandatory hide-arrows>
        <v-slide-item v-for="(item, i) in tabList" :key="i" v-slot="{ active, toggle }">
          <v-btn depressed :color="active ? 'activeColor white--text' : 'unActiveColor black--text'" height="26"
            min-width="50" class="fsize12 rounded-sm text-center text-capitalize" :value="item.name" @click="toggle"
            :id="`${item.name}_menu`">
            {{ item.name }}
            <span v-if="vendorList.length != 0">&nbsp;({{ vendorList.length }})</span>
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <v-divider class="mx-4 my-2"></v-divider>

    <div class="mb-4">
      <v-card class="mx-4" id="discover_card">
        <v-sheet tile v-for="(n, index) in vendorList" :key="index" class="cursor" @click="validateAuth(n)"
          :id="`${index}_discover_sheet`">
          <div class="row ma-0">
            <v-flex xs12 sm12 md12 lg12 xl12 class="pa-3">
              <div class="d-flex justify-space-between">
                <div class="d-flex">
                  <label :id="`${index}_discover_name`" class="
                fsize16
                primaryColor
                font-weight-bold
                l-height-28
                ml-2
                pt-2
                pb-1
              ">{{ n.vendorName }}</label> <img v-if="n.svg" class="ml-2" style="width: 4%;" src="../assets/images/New Symbol.svg" alt="">
                </div>
                <v-btn depressed class="  fsize14 text-capitalize activeColor white--text cursor"
                  :id="`${index}_discover_expore_btn`" min-width="80px" height="32px">Explore</v-btn>
              </div>
              <div>
                <p class="fsize13 secondColor ml-2 mt-2" :id="`${index}_discover_descrip`">
                  {{ n.description }}
                </p>
              </div>
            </v-flex>
          </div>
          <v-divider></v-divider>
        </v-sheet>
      </v-card>
    </div>

    <v-row class="justify-center">
    </v-row>
  </div>
</template>

<script>
import { which } from '../../package.json'
export default {
  data() {
    return {
      infoType: null,
      tabList: [
        {
          name: 'Discover',
          length: 2
        }
      ],
      vendorList: [
        // {
        //   vendorName: "Mutual Fund's",
        //   description: 'Mutual funds provide a range of benefits for investors, including a systematic investment plan, expert fund management, low investment threshold, high liquidity, and multiple investment alternatives. The user-friendly investing process makes it convenient and easy for investors to diversify their portfolio and potentially achieve better returns.',
        //   url: 'https://mf.adityabirlamoney.com/singlesignontoken.aspx',
        //   key: 'MF',
        //   svg: false,
        //   vendor: 'CMOTS'
        // },
        {
          vendorName: "Mutual Fund's",
          description: 'Mutual funds provide a range of benefits for investors, including a systematic investment plan, expert fund management, low investment threshold, high liquidity, and multiple investment alternatives. The user-friendly investing process makes it convenient and easy for investors to diversify their portfolio and potentially achieve better returns.',
          url: 'https://newmf.adityabirlamoney.com/SingleSignOn',
          key: 'MF',
          svg: true,
          vendor:'OPTIMUM'
        },
        {
          vendorName: "IPO",
          description: 'Investing in an IPO has become more easy with e-commerce-style tracking and easy registration of payment details. IPOs generate anticipation in the market, providing a chance to invest in promising new companies and potentially benefit from their growth and success.',
          url: 'https://ipo.adityabirlamoney.com/singlesignontoken.aspx',
          key: 'IPO',
          svg: false,
          vendor: 'CMOTS'
        },
        // {
        //   vendorName: "WealthBasket",
        //   description: 'WealthBaskets is a combination of Equities and ETFs created and managed by SEBI registered professionals. Each WealthBasket reflects an investment strategy, theme or sector. No hidden costs, No lock-in period, Direct receipt of dividends & a complete control over investments.',
        //   url: 'https://wealthdesk.adityabirlamoney.com/explore',
        //   key: 'WH',
        //   svg: false,
        //   vendor: 'WEALTHDESK'
        // },
        {
          vendorName: "InvestPack",
          description: 'Pickright offers Investpack - a ready-to-invest portfolio that is strategically diversified, seamlessly rebalanced and optimised for your goal.',
          url: which == 'Live' ? 'https://webview.investpack.in/ABML?vendorToken=QUJNTDoxYjBkZjZjYjQwNTUxMGUwZWE4NmRjYzM5MWEzYjhjMw==&requestToken=' : 'https://webview.investpack.in/ABML?vendorToken=QUJNTDoxYjBkZjZjYjQwNTUxMGUwZWE4NmRjYzM5MWEzYjhjMw==&requestToken=',
          key: 'PR',
          svg: false,
          vendor: 'PICKRIGHT'
        },
        {
          vendorName: "SGB",
          description: 'Invest in stability and prosperity through Sovereign Gold Bonds (SGBs), which are government-backed financial instruments. SGBs offer an easy and cost-effective way to own gold without the hassle of physical storage and purity concerns',
          url: 'https://sgb.adityabirlamoney.com',
          key: 'SGB',
          svg: false,
          vendor: 'Bond bulls'
        },
      ]
    }
  },
  methods: {
    validateAuth(app) {
      this.$store.commit('authdialog/setAuthPayload', '')
      if (app.key == 'IPO') {
        this.$store.dispatch('authentication/generateAuthCode', { redirectUrl: app.url, vendor: app.vendor })
        this.$store.commit('authdialog/setAuthPayload', { redirectUrl: app.url, vendor: app.vendor })
      }
      if (app.key == 'MF') {
        this.$store.dispatch('authentication/generateAuthCode', { redirectUrl: app.url, vendor: app.vendor })
        this.$store.commit('authdialog/setAuthPayload', { redirectUrl: app.url, vendor: app.vendor })
      }
      if (app.key == 'WH') {
        this.$store.dispatch('authentication/generateAuthCode', { redirectUrl: app.url, vendor: app.vendor })
        this.$store.commit('authdialog/setAuthPayload', { redirectUrl: app.url, vendor: app.vendor })
      }
      if (app.key == 'PR') {
        this.$store.dispatch('authentication/generateAuthCode', { redirectUrl: app.url, vendor: app.vendor })
        this.$store.commit('authdialog/setAuthPayload', { redirectUrl: app.url, vendor: app.vendor })
      }
      if (app.key == 'SGB') {
        this.$store.dispatch('authentication/generateAuthCode', { redirectUrl: app.url, vendor: app.vendor })
        this.$store.commit('authdialog/setAuthPayload', { redirectUrl: app.url, vendor: app.vendor })
      }
    }
  }
}
</script>

<style></style>